<template>
  <div class="container-fluid p-4">
    <div class="d-flex flex-column">
      <h1>License</h1>
      <div class="py-2"></div>
      <b-card class="bg-surface-2">
        <b-card-text>
          <div class="d-flex mb-2">
            <div class="w-5"><strong>Mode</strong></div>
            <div class="mx-2">:</div>
            <div>{{ this.sc_mode | humanizeText }}</div>
          </div>

          <div class="d-flex mb-2">
            <div class="w-5"><strong>Status</strong></div>
            <div class="mx-2">:</div>
            <div class="d-flex align-items-center">
              <div
                class="status-indicator mr-1"
                :class="[active == 1 ? 'status-online' : 'status-offline']"
              ></div>
              {{ active ? "Active" : "Inactive" }}
            </div>
          </div>

          <div class="d-flex mb-2">
            <div class="w-5"><strong>Expires</strong></div>
            <div class="mx-2">:</div>
            <div>{{ this.exp }}</div>
          </div>
        </b-card-text>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
let utils = require("@/assets/global/js/utils.js");

export default {
  data() {
    return {
      sc_mode: "",
      exp: "",
      sc_cam: "",
      active: false
    };
  },
  computed: {
    ...mapGetters({
      getCurrentUser: "session/getCurrentUser",
      getAPIServerURL: "session/getAPIServerURL"
    })
  },
  mounted: function () {
    if (this.getCurrentUser != null) {
      this.getLicenseData();
    }
  },
  methods: {
    getLicenseData() {
      var $this = this;
      var API_URL = $this.getAPIServerURL + "/license/info/";
      const client = $this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL)
        .then((data) => {
          let result = data.result;

          for (const url in result) {
            if (result[url] != null) {
              this.sc_mode = result[url]["sc_mode"];
              this.sc_cam = result[url]["sc_cam"];

              let exp = moment(result[url]["exp"]);
              let now = moment();

              let diffSec = exp.diff(now);

              if (diffSec > 0) {
                this.active = true;
              } else {
                this.active = false;
              }

              this.exp = exp.format("DD/MM/YYYY HH:mm:ss");
            }
          }
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    }
  }
};
</script>

<style lang="scss">
.status-indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;

  &.status-online {
    background-color: $success;
  }

  &.status-offline {
    background-color: $danger;
  }
}
</style>
